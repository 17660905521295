
<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <h1 class="">Olá, {{ manager.name }}. Bem-vindo ao Snackly</h1>
      </div>
    </div>
    <div class="row">
      <div
        v-for="service in renderServices"
        class="col-md-4"
        :key="service.name"
      >
        <card>
          <h5 slot="header" class="title">Serviço</h5>
          <div>
            <h2>{{ service.name }}</h2>
          </div>
          <router-link :to="service.routerLink" class="btn btn-success"
            >Acessar</router-link
          >
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex/dist/vuex.common.js";
import { PERMISSIONS } from "../services/permissions";

export default {
  name: "DefaultPage",
  data() {
    return {
      manager: {},
    };
  },
  mounted() {
    this.manager = this.$store.getters["manager/getManager"];
  },
  methods: {
    ...mapGetters({
      isAdmin: "manager/isManagerAdmin",
      getManager: "manager/getManager",
    }),
    hasPermissions(routerName) {
      const manager = this.getManager();
      const { user_type } = manager;
      return this.isAdmin()
        ? this.isAdmin()
        : PERMISSIONS[user_type].includes(routerName);
    },
  },
  computed: {
    renderServices() {
      const services = {
        dashboard: {
          name: "Gráficos",
          routerLink: "/dashboard",
        },
        purchases: {
          name: "Compras",
          routerLink: "/purchases",
        },
        machines: {
          name: "Máquinas",
          routerLink: "/machines",
        },
        stock: {
          name: "Estoque",
          routerLink: "/stock",
        },
        products: {
          name: "Produtos",
          routerLink: "/products",
        },
        moviments: {
          name: "Movimentação",
          routerLink: "/moviments",
        },
        report: {
          name: "Relatório de Vendas",
          routerLink: "/report",
        },
        createStockInventory: {
          name: "Criar inventário do estoque",
          routerLink: "/create/stock/inventory",
        },
        stockInventories: {
          name: "Inventários do Estoque",
          routerLink: "/stock/inventories",
        },
        createMachineInventory: {
          name: "Criar inventário da máquina",
          routerLink: "/create/machine/inventory",
        },
        machineInventories: {
          name: "Inventários das máquinas",
          routerLink: "/machines/inventories",
        },
      };
      const permissionsItems = {};
      for (const name in services) {
        if (this.hasPermissions(name)) {
          permissionsItems[name] = services[name];
        }
      }
      return permissionsItems;
    },
  },
};
</script>

<style lang="css" scoped>
</style>
