<template>
  <div>
    <LoadingSpinner :show="loading" />
    <div class="row align-center justify-content-between">
      <div class="col-12 col-md-4">
        <label for="inputMachine" class="form-label text-light"
          >Selecione uma máquina</label
        >
        <select
          v-model="selectedMachine"
          class="form-control"
          id="inputMachine"
        >
          <option class="bg-dark" :selected="!selectedMachine" :value="null">
            --- Todas as máquinas ---
          </option>
          <option
            v-for="machine in machineList"
            :value="machine.machine_id"
            :key="machine.machine_id"
            class="bg-dark"
          >
            {{ machine.description }}
          </option>
        </select>
      </div>
    </div>
    <div class="row align-center justify-content-end">
      <div class="col-12 col-md-6">
        <div
          class="btn-group btn-group-toggle"
          :class="isRTL ? 'float-left' : 'float-right'"
          data-toggle="buttons"
        >
          <div
            key="startDate"
            for="startDate"
            name="startDate"
            class="datepicker-container btn btn-sm btn-primary btn-simple"
            @click="activeIndex = 0"
          >
            <label
              for="startDate"
              class="btn btn-sm btn-primary btn-simple startDate"
              >Data Inicial</label
            >
            <input
              class="text-primary date-picker"
              type="date"
              v-model="startDate"
              name="startDate"
              id="startDate"
              @change="handleChangeDate"
            />
          </div>
          <div
            key="endDate"
            for="endDate"
            name="endDate"
            class="datepicker-container btn btn-sm btn-primary btn-simple"
            @click="activeIndex = 1"
          >
            <label
              for="endDate"
              class="btn btn-sm btn-primary btn-simple endDate"
              >Data Final</label
            >
            <input
              class="text-primary date-picker"
              type="date"
              v-model="endDate"
              name="endDate"
              id="endDate"
              @change="handleChangeDate"
            />
          </div>
          <div
            for="period"
            class="datepicker-container btn btn-sm btn-primary btn-simple"
          >
            <label for="period" class="btn btn-sm btn-primary btn-simple period"
              >Frequência</label
            >
            <span class="text-primary" v-if="!frequency"
              >Selecione um período</span
            >
            <select
              id="period"
              v-model="frequency"
              class="btn btn-sm btn-primary btn-simple select-period text-primary"
              name="period"
            >
              <option
                class="bg-dark text-light"
                :disabled="getDaysRange <= 31"
                value="monthly"
              >
                Mensal
              </option>
              <option
                class="bg-dark text-light"
                :disabled="getDaysRange <= 30"
                value="weekly"
              >
                Semanal
              </option>
              <option
                class="bg-dark text-light"
                :disabled="getDaysRange > 31"
                value="daily"
              >
                Diário
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row my-5">
      <div class="col-lg-12" :class="{ 'text-right': isRTL }">
        <SalesChart
          :startDate="startDate"
          :endDate="endDate"
          :frequency="frequency"
          :selectedMachine="selectedMachine"
        />
      </div>
      <div class="col-lg-6" :class="{ 'text-right': isRTL }">
        <ProductChart
          :startDate="startDate"
          :endDate="endDate"
          :selectedMachine="selectedMachine"
        />
      </div>
      <div class="col-lg-6" :class="{ 'text-right': isRTL }">
        <MachineChart
          :startDate="startDate"
          :endDate="endDate"
          :selectedMachine="selectedMachine"
        />
      </div>
      <div class="col-lg-12" :class="{ 'text-right': isRTL }">
        <LocationChart
          :startDate="startDate"
          :endDate="endDate"
          :selectedMachine="selectedMachine"
        />
      </div>
    </div>
  </div>
</template>
<script>
import LocationChart from "@/components/Charts/LocationChart.vue";
import MachineChart from "@/components/Charts/MachineChart.vue";
import ProductChart from "@/components/Charts/ProductChart.vue";
import SalesChart from "@/components/Charts/SalesChart.vue";
import LoadingSpinner from "@/components/Spinner.vue";
import moment from "moment";
import { format } from "../mixins/format.js";
moment().format();

export default {
  components: {
    LoadingSpinner,
    SalesChart,
    ProductChart,
    MachineChart,
    LocationChart,
  },
  mixins: [format],
  data() {
    return {
      selectedMachine: null,
      machineList: [],
      loading: false,
      startDate: "",
      endDate: "",
      activeIndex: 0,
      frequency: "",
      daysRange: 0,
    };
  },
  computed: {
    getDaysRange() {
      const ONE_DAY_IN_SECONDS = 1000 * 60 * 60 * 24;
      if (!this.validateStartAndFinalDate()) return 0;
      const startDate = this.rawDateToDateObject(this.startDate);
      const endDate = this.rawDateToDateObject(this.endDate);
      const timeDifference = Math.abs(startDate - endDate);
      const daysDifference = Math.ceil(timeDifference / ONE_DAY_IN_SECONDS);
      return daysDifference;
    },
    enableRTL() {
      return this.$route.query.enableRTL;
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    bigLineChartCategories() {
      return this.$t("dashboard.chartCategories");
    },
  },
  mounted() {
    this.getMachines();
    if (!this.startDate && !this.endDate) {
      this.startDate = moment().weekday(1).format("YYYY-MM-DD");
      this.endDate = moment().weekday(6).format("YYYY-MM-DD");
      this.handleChangeDate();
    }
    this.i18n = this.$i18n;
    if (this.enableRTL) {
      this.i18n.locale = "ar";
      this.$rtl.enableRTL();
    }
  },
  beforeDestroy() {
    if (this.$rtl.isRTL) {
      this.i18n.locale = "en";
      this.$rtl.disableRTL();
    }
  },
  methods: {
    async getMachines() {
      try {
        this.loading = true;
        const { data } = await this.$http.get("/machines?limit=100", {
          params: {
            company_id:
              this.$store.getters["company/getManagerCompany"].company_id,
          },
        });

        this.machineList = data.machines;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    rawDateToDateObject(date) {
      let [year, month, day] = date.split("-");
      const newDate = new Date(year, month - 1, day);
      return newDate;
    },
    validateStartAndFinalDate() {
      if (!this.endDate || !this.startDate) {
        return false;
      }
      return true;
    },
    changeFrequency() {
      const daysDifference = this.getDaysRange;
      if (daysDifference <= 31) this.frequency = "daily";
      if (daysDifference > 31) this.frequency = "weekly";
      if (daysDifference > 170) this.frequency = "monthly";
    },
    async handleChangeDate() {
      this.changeFrequency();
    },
  },
};
</script>
<style>
.select-period {
  font-size: 12px;
}

label.endDate,
label.startDate,
label.period {
  color: #fff !important;
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

label.period {
  pointer-events: none;
}

.btn-primary.btn-simple.active:hover,
.btn-primary.btn-simple.active:focus,
.btn-primary.btn-simple.active:active,
.btn-primary.btn-simple.active:not(:disabled):not(.disabled):active {
  background: transparent !important;
}

.btn-primary.btn-simple.active {
  background: transparent !important;
}

.datepicker-container {
  display: flex;
  flex-direction: column;
}

#startDate,
#endDate {
  background: transparent;
  font-weight: 600;
  border: none;
}

.date-picker {
  cursor: pointer;
}

#period {
  background: transparent;
  border: none;
  position: absolute;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
  width: 100%;
  height: 100%;
}
</style>
