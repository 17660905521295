<template>
  <card type="chart">
    <LoadingSpinner :show="loading" />
    <div class="px-3" style="display: flex; justify-content: space-between">
      <div class="titles">
        <h5 class="card-category">Gráfico Snackly</h5>
        <h2 v-if="!startDate || !endDate" class="card-title"></h2>
        <h2 v-else class="card-title">{{ purchasesTotal }}</h2>
      </div>
    </div>
    <div class="chart-area">
      <line-chart
        style="height: 100%"
        ref="bigChart"
        chart-id="big-line-chart"
        :chart-data="bigLineChart.chartData"
        :gradient-colors="bigLineChart.gradientColors"
        :gradient-stops="bigLineChart.gradientStops"
        :extra-options="bigLineChart.extraOptions"
      >
      </line-chart>
    </div>
  </card>
</template>
<script>
import LineChart from "@/components/Charts/LineChart";
import * as chartConfigs from "@/components/Charts/config";
import LoadingSpinner from "@/components/Spinner.vue";
import config from "@/config";
import moment from "moment";

const defaultOptions = {
  fill: true,
  borderWidth: 2,
  borderDash: [],
  borderDashOffset: 0.0,
  pointBorderColor: "rgba(255,255,255,0)",
  pointBorderWidth: 20,
  pointHoverRadius: 4,
  pointHoverBorderWidth: 15,
  pointRadius: 4,
};
const options = {
  ...defaultOptions,
  label: "Vendas",
  borderColor: config.colors.danger,
  pointBackgroundColor: config.colors.danger,
  pointHoverBackgroundColor: config.colors.danger,
};
export default {
  name: "SalesChart",
  components: {
    LineChart,
    LoadingSpinner,
  },
  props: {
    startDate: String,
    endDate: String,
    frequency: String,
    selectedMachine: String,
  },
  data() {
    return {
      loading: false,
      hasMachineSelected: null,
      hasStartDate: null,
      hasEndDate: null,
      purchasesTotal: 0,
      bigLineChart: {
        chartData: {
          datasets: [
            {
              label: "Vendas",
              ...options,
              data: [0],
            },
          ],
          labels: [],
        },
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
        categories: [],
      },
    };
  },
  mounted() {},
  methods: {
    formatedDate(date) {
      if (!date) return;
      const [year, month, day] = date.split("-");
      const newDate = new Date(year, month - 1, day);
      return new Intl.DateTimeFormat("pt-BR").format(newDate);
    },
    validateStartAndFinalDate() {
      return this.endDate || this.startDate;
    },
    getAllDateBetweenInitialAndFinalDate(steps = 1) {
      const rangeDatesInitalAndFinal = [];
      let newStartDate = moment(this.startDate).format("YYYY-MM-DD");
      let newEndDate = moment(this.endDate).format("YYYY-MM-DD");
      while (moment(newStartDate).isSameOrBefore(moment(newEndDate))) {
        rangeDatesInitalAndFinal.push(
          moment(newStartDate).format("YYYY-MM-DD")
        );
        newStartDate = moment(newStartDate).add(steps, "d");
      }
      return rangeDatesInitalAndFinal;
    },
    async getPurchases() {
      this.loading = true;
      const response = await this.$http.get(
        `/graph/purchases?start_date=${this.startDate}&end_date=${this.endDate}&machine_id=${this.selectedMachine}`
      );
      const { purchases } = response.data;
      this.loading = false;
      return purchases;
    },
    dateHasPurchase(purchases, date) {
      return purchases.find((purchase) => {
        return moment(purchase.date).format("YYYY-MM-DD") == date;
      });
    },
    async generateSalesChart() {
      if (!this.validateStartAndFinalDate()) return;
      const purchaseDataset = await this.generatePurchaseDataset();
      this.createPurchaseChart(purchaseDataset);
    },
    async generatePurchaseDataset() {
      try {
        const allDate = this.getAllDateBetweenInitialAndFinalDate();
        let purchasesTotal = 0;
        const purchaseChart = {
          labels: [...allDate],
          data: [],
        };
        const purchases = await this.getPurchases();
        if (!purchases) return;
        for (let purchaseDate of purchaseChart.labels) {
          if (this.dateHasPurchase(purchases, purchaseDate)) {
            purchaseChart.data.push(this.dateHasPurchase.total);
          } else {
            purchaseChart.data.push(0);
          }
        }
        const purchasesTotalByDate = purchases.reduce(
          (purchasesTotal, purchaseDay) => {
            const date = moment(purchaseDay.date).format("YYYY-MM-DD");
            if (date in purchasesTotal) {
              purchasesTotal[date] += purchaseDay.total;
            } else {
              purchasesTotal[date] = purchaseDay.total;
            }
            return purchasesTotal;
          },
          {}
        );
        for (let key in purchasesTotalByDate) {
          const indexOfPurchase = purchaseChart.labels.indexOf(key);
          const newValue = purchasesTotalByDate[key];
          purchasesTotal += newValue;
          purchaseChart.data[indexOfPurchase] = newValue / 100;
        }
        this.purchasesTotal = new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(purchasesTotal / 100);
        localStorage.setItem(
          "@Snackly:purchaseChartData",
          JSON.stringify(purchaseChart)
        );
        return purchaseChart;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    createChart({ datasets, labels, chartName }) {
      let datasetsWithOption = datasets[0];
      datasetsWithOption = [{ ...options, ...datasetsWithOption }];
      this[chartName].chartData = { datasets: datasetsWithOption, labels };
    },
    createPurchaseMonthlyChart(purchaseDataset) {
      if (!purchaseDataset) return;
      const chartLabelAndData = [];
      const purchaseData = purchaseDataset.data;
      const purchasesLabels = purchaseDataset.labels;
      for (let i = 0; i < purchaseData.length; i++) {
        chartLabelAndData.push({
          label: purchasesLabels[i],
          data: purchaseData[i],
        });
      }
      const monthsData = chartLabelAndData.reduce((acc, obj) => {
        let [year, month, day] = obj.label.split("-");
        const date = new Date(year, month - 1, day);
        const monthName = date
          .toLocaleString("pt-BR", { month: "long" })
          .toUpperCase();
        const monthAndYear = `${monthName}/${year}`;
        acc[monthAndYear] ??= 0;
        acc[monthAndYear] = acc[monthAndYear] + obj.data;
        return acc;
      }, {});
      let chartData = { data: [], labels: [] };
      Object.entries(monthsData).forEach(([key, value]) => {
        chartData.data.push(value);
        chartData.labels.push(key);
      });
      const { data, labels } = chartData;
      const datasets = [{ data, label: "Vendas" }];
      this.createChart({
        datasets,
        labels,
        chartName: "bigLineChart",
      });
    },
    createPurchaseWeeklyChart(purchaseDataset) {
      if (!purchaseDataset) return;
      const chartLabelAndData = [];
      const purchaseData = purchaseDataset.data;
      const purchasesLabels = purchaseDataset.labels;
      for (let index in purchaseDataset.data) {
        chartLabelAndData.push({
          label: purchasesLabels[index],
          data: purchaseData[index],
        });
      }
      const weeklyData = chartLabelAndData.reduce((acc, obj) => {
        const weekNumber = moment(obj.label).week();
        const date = new Date(obj.label);
        date.toLocaleDateString("default", { month: "long" });
        const monthName = date
          .toLocaleDateString("pt-BR", { month: "long" })
          .toUpperCase();
        const keyLabel = `${weekNumber}/${monthName}`;
        if (!acc[keyLabel]) acc[keyLabel] = [];
        acc[keyLabel].push(obj);
        return acc;
      }, {});
      let chartData = { data: [], labels: [] };
      Object.entries(weeklyData).forEach(([key, value]) => {
        const total = value.reduce((acc, obj) => {
          acc += obj.data;
          return acc;
        }, 0);
        chartData.data.push(total);
        chartData.labels.push(key);
      }, {});
      const { data, labels } = chartData;
      const datasets = [{ data, label: "Vendas" }];
      this.createChart({
        datasets,
        labels,
        chartName: "bigLineChart",
      });
    },
    createPurchaseDailyChart(purchaseDataset) {
      if (!purchaseDataset) return;
      const newPurchasesLabel = purchaseDataset.labels.map((date) => {
        return this.formatedDate(date);
      });
      delete purchaseDataset.labels;
      purchaseDataset.labels = [...newPurchasesLabel];
      const { data, labels } = purchaseDataset;
      const datasets = [{ data, label: "Vendas" }];
      this.createChart({
        datasets,
        labels,
        chartName: "bigLineChart",
      });
    },
    createPurchaseChart(purchaseDataset) {
      switch (this.frequency) {
        case "daily":
          this.createPurchaseDailyChart(purchaseDataset);
          break;
        case "weekly":
          this.createPurchaseWeeklyChart(purchaseDataset);
          break;
        case "monthly":
          this.createPurchaseMonthlyChart(purchaseDataset);
          break;
        default:
          break;
      }
    },
  },
  watch: {
    selectedMachine() {
      this.hasMachineSelected = this.selectedMachine;
      this.generateSalesChart();
    },
    startDate() {
      if (
        this.startDate == this.hasStartDate &&
        this.endDate == this.hasEndDate
      )
        return;
      this.hasStartDate = this.startDate;
      this.hasEndDate = this.endDate;
      this.generateSalesChart();
    },
    endDate() {
      if (
        this.startDate == this.hasStartDate &&
        this.endDate == this.hasEndDate
      )
        return;
      this.hasStartDate = this.startDate;
      this.hasEndDate = this.endDate;
      this.generateSalesChart();
    },
    frequency() {
      this.generateSalesChart();
    },
  },
};
</script>

<style></style>